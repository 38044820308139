import { UseFormRegisterReturn } from "react-hook-form"

type TextInputProps = React.ComponentProps<"input"> & {
    register?: UseFormRegisterReturn<string>
}

export default function TextInput({
    className,
    register,
    type = "text",
    ...props
}: TextInputProps) {
    return (
        <input
            {...props}
            type={type}
            className={`
                rounded-3xl border-none bg-bonza-dark p-4 text-bonza-pale

                focus:border-bonza-primary focus:ring-bonza-primary

                ${className}
            `}
            {...register}
        />
    )
}
